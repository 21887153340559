<div
    class="section-header-container"
    data-automation="site-configuration-header-container"
>
    <h4 class="section-header" data-automation="site-configuration-header">
        {{ 'site.configuration' | translate }}
    </h4>
</div>

<div class="container">
    <div
        *ngIf="configurations?.length"
        data-automation="site-configuration-table-container"
    >
        <table
            data-automation="site-configuration-table"
            mat-table
            matSort
            [dataSource]="dataSource"
            (matSortChange)="sortData($event)"
        >
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="type">
                    {{ 'site.configuration.der_type' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-type"
                >
                    <ng-container [ngSwitch]="configuration['type']">
                        <ng-container *ngSwitchCase="'BATTERY'">
                            <img
                                src="../../../../../assets/images/battery.svg"
                                class="images"
                                alt="yellow"
                            />
                        </ng-container>
                        <ng-container *ngSwitchCase="'FUEL_CELL'">
                            <img
                                src="../../../../../assets/images/oval-green.svg"
                                class="images"
                                alt="green"
                            />
                        </ng-container>
                        <ng-container *ngSwitchCase="'SOLAR'">
                            <img
                                src="../../../../../assets/images/oval-red.svg"
                                class="images"
                                alt="red"
                            />
                        </ng-container>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="name">
                    {{ 'site.configuration.der_name' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-name"
                >
                    {{ configuration.displayLabel }}
                </td>
            </ng-container>

            <ng-container matColumnDef="oem">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="oem">
                    {{ 'site.configuration.oem' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-oem"
                >
                    {{ configuration.oem.displayLabel }}
                </td>
            </ng-container>

            <ng-container matColumnDef="rack-batteries">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="rackBatteries"
                >
                    {{ 'site.configuration.rack_batteries' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-rack-batteries"
                >
                    {{ configuration.rack + ' / ' + configuration.batteries }}
                </td>
            </ng-container>

            <ng-container matColumnDef="power-capacity">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="powerCapacity"
                >
                    {{ 'site.configuration.power_capacity' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-power-capacity"
                >
                    {{ configuration.powerCapacity + ' kW' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="energy-capacity">
                <th
                    mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header="energyCapacity"
                >
                    {{ 'site.configuration.energy_capacity' | translate }}
                </th>
                <td
                    mat-cell
                    *matCellDef="let configuration"
                    data-automation="site-configuration-table-energy-capacity"
                >
                    {{ configuration.energyCapacity + ' kWh' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                *matRowDef="let configuration; columns: displayedColumns"
                id="{{ 'configuration-table-' + configuration.id }}"
            ></tr>
        </table>

        <mat-paginator
            [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Select page of users"
        ></mat-paginator>
    </div>
</div>
