import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

/**
 * Wrapping the translateService for DesCo purposes, including fallback rules, branding, etc
 */
@Injectable()
export class CookieService {
  cookies: object;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.cookies = {};
    this.document.cookie.split(";").map(variable => {
      const variableParts = variable.split("=");
      this.cookies[variableParts[0].trim()] = variableParts[1];
    });
  }

  // Need to import the angular cookie service
  getCookie(cname: string | number, defaulter: unknown = null): string {
    return this.cookies[cname] || defaulter;
  }

  getDesSession(): string {
    return this.getCookie("des_session");
  }

  getMarketsMock(): string {
    return this.getCookie("markets_mock");
  }

  getLocale(): string {
    return this.getCookie("locale") || "en_US";
  }
  getRawI18nLocale(): string {
    return this.getCookie('locale') || '';
  }

  /**
   * Gets the locale cookie formatted for i18n
   */
  getI18NLocale(): string {
    const raw = this.getCookie('locale') || '';
    return raw.replace('_', '-').toLowerCase();
  }

  /**
   * Gets the brand value formatted for i18n
   */
  getI18NBrand(): string {
    const raw = this.getCookie('default_brand') || '';
    return raw.toUpperCase();
  }


}
