import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TreeNode } from 'tree/dist/tree/lib/models/tree-node';
import { MatIconRegistry } from '@angular/material/icon';
import { NodeDefinition } from 'tree/dist/tree';
import { Subscription } from 'rxjs';
import { Account, Developer, Site } from '@model';
import { DeveloperService, SidenavService } from '@service';

@Component({
  selector: 'deros-dashboard-tree',
  templateUrl: './deros-tree.component.html',
  styleUrls: ['./deros-tree.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class DerosTreeComponent implements OnDestroy, OnInit {
  loading = true;

  subscribers: Subscription[] = [];
  clicked: TreeNode<Developer | Account | Site>;
  expandedNodes: Set<TreeNode<Developer | Account | Site>> = new Set<
    TreeNode<Developer | Account | Site>
  >();
  selectedNodes: Set<TreeNode<Developer | Account | Site>> = new Set<
    TreeNode<Developer | Account | Site>
  >();
  developers: Developer[] = [];
  treeData: TreeNode<Developer | Account | Site>[] = [];
  selection: TreeNode<Developer | Account | Site>[] = [];
  shownExpandedNodes: TreeNode<Developer | Account | Site>[] = [];
  clickedNode: TreeNode<Developer | Account | Site>;
  checkbox = false;
  expandable = true;
  search = true;
  selectAllResults = false;
  allowDeselectAll = false;
  maxSelections = 1;
  cascadeSelect = false;
  expandAll = false;
  labelIdentifier = 'displayLabel';
  iconIdentifier = 'spaceType';
  SITE_DR_TYPE = 'Site';
  ACCOUNT_DR_TYPE = 'Account';
  iconDefinitions: { [key: string]: string } = {
    PEOPLE_ALT: 'https://cdn.dev.denms.net/img/icons/people_alt.svg',
    FOLDER_OPEN_OUTLINED:
      'https://cdn.dev.denms.net/img/icons/folder_open_outlined.svg',
    FACTORY: 'https://cdn.dev.denms.net/img/icons/factory.svg',
    DESCO: 'https://cdn.dev.denms.net/img/icons/desco.svg',
  };
  iconMap: { [key: string]: string } = {
    Developer: 'PEOPLE_ALT',
    Account: 'FOLDER_OPEN_OUTLINED',
    Site: 'FACTORY',
  };
  iconKeys: string[] = [
    'PEOPLE_ALT',
    'FOLDER_OPEN_OUTLINED',
    'FACTORY',
    'DESCO',
  ];
  disabledNodeAttribute = 'spaceType';
  disabledNodeValues: string[] = ['Developer'];
  searchProperties: string[] = ['displayLabel'];
  disabledNodeDefinitions: NodeDefinition = {
    [this.disabledNodeAttribute]: this.disabledNodeValues,
  };

  constructor(
    private router: Router,
    private developerService: DeveloperService,
    private sidenavService: SidenavService,
    private ngZone: NgZone,
    iconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
  ) {
    Object.keys(this.iconDefinitions).forEach((iconName: string) => {
      console.log(iconName);
      iconRegistry.addSvgIcon(
        iconName,
        domSanitizer.bypassSecurityTrustResourceUrl(
          this.iconDefinitions[iconName],
        ),
      );
    });
  }
  ngOnInit(): void {
    const developersSubscriber = this.developerService
      .getDevelopers$()
      .subscribe(developers => {
        this.developers.push(...developers);
        this.treeData = this.developers;

        if (this.developers[0]) {
          this.expandedNodes.add(this.developers[0]);
        }

        // TODO: might break like this, make this selection more dynamic to be the first selectable thing in the list
        if (
          this.developers[0]?.children &&
          this.developers[0].children.length > 0
        ) {
          this.expandedNodes.add(this.developers[0].children[0]);
        }
        this.loading = false;
      });

    this.subscribers.push(developersSubscriber);
  }

  ngOnDestroy(): void {
    this.subscribers.forEach(subscriber => subscriber.unsubscribe());
  }

  initClicked(clicked: TreeNode<Developer | Account | Site>): void {
    if (!clicked[0]) {
      return;
    }

    this.clicked = clicked[0];
    if (this.clicked['spaceType']?.toLowerCase() === 'account') {
      this.onAccountClicked(this.clicked);
    }
    if (this.clicked['spaceType']?.toLowerCase() === 'site') {
      this.onSiteClicked(this.clicked);
    }
  }

  onAccountClicked(account: TreeNode<Developer | Account | Site>): void {
    this.ngZone.run(() => {
      this.router.navigate([`/details/${account.id}/view-account`], {
        queryParams: { accountId: account['parentId'] },
      });
    });
  }

  onSiteClicked(site: TreeNode<Developer | Account | Site>): void {
    this.ngZone.run(() => {
      this.router.navigate([`/details/${site.id}/view-site`], {
        queryParams: { siteId: site['parentId'] },
      });
    });
  }

  expansionHandler(nodes: TreeNode<Developer | Account | Site>[]): void {
    this.shownExpandedNodes = nodes;
  }

  selectionHandler(nodes: TreeNode<Developer | Account | Site>[]): void {
    if (!nodes[0]) {
      return;
    }

    if (nodes[0]['dr_type'] == this.SITE_DR_TYPE) {
      this.onSiteClicked(nodes[0]);
    }

    if (nodes[0]['dr_type'] == this.ACCOUNT_DR_TYPE) {
      this.onAccountClicked(nodes[0]);
    }
  }

  clickedHandler(node: TreeNode<Developer | Account | Site>) {
    this.clickedNode = node;
  }
}
