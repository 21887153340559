import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalAlertService } from './global-alert.service';
import { ResourceService } from './resource.service';
import { TimezonesService } from './timezones.service';
import { Developer } from '@model';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class DeveloperService {
  private _developers: Developer[] = [];
  get developers(): Developer[] {
    return this._developers;
  }

  private readonly developersEmitter = new Subject<Developer[]>();
  private readonly searchFilterEmitter = new Subject<string>();

  constructor(
    private resourceService: ResourceService<Developer>,
    private timezoneService: TimezonesService,
    private alertService: GlobalAlertService,
  ) {}

  getDevelopers$(): Observable<Developer[]> {
    return fromPromise<Developer[]>(
      this.resourceService.getTree(['developer', 'account', 'site']) as Promise<
        Developer[]
      >,
    ).pipe(
      tap(developers => {
        this.developersEmitter.next(developers);
        this._developers = [...developers];
      }),
      catchError((error: unknown) => {
        this.alertService.setError(error.toString());
        this.developersEmitter.error(error);
        return throwError(() => new Error(error.toString()));
      }),
    );
  }

  sendValue(value: string): void {
    this.searchFilterEmitter.next(value);
  }
}
