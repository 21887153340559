import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DerosTreeComponent } from './deros-tree.component';
import { TreeModule } from 'tree/dist/tree';

@NgModule({
  declarations: [DerosTreeComponent],
  exports: [DerosTreeComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatRippleModule,
    TreeModule,
  ],
})
export class DerosTreeModule {}
