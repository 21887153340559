<div class="flex flex-col justify-start items-center">
    <div class="w-full flex justify-center items-center title-heading">
        <div
            class="flex justify-start items-center justify-self-start margin-right-auto icon-header"
        >
            <div class="icon-container">
                <mat-icon svgIcon="manage-white" class="header-icon"></mat-icon>
            </div>
            <div>
                <h1>{{ account.displayLabel }}</h1>
            </div>
        </div>
    </div>
</div>

<div class="page-container">
    <div class="section-header-container">
        <h4 class="section-header" data-automation="site-locations-label">
            {{ 'sites.locations' | translate }}
        </h4>
    </div>

    <div class="details-container">
        <div class="details-row">
            <div class="details-half-row location-container">
                <div id="map-detail" class="map-container">
                    <map-view
                        [zoom]="4"
                        [usePanning]="true"
                        [latitude]="account.children[0].settings.latitude"
                        [longitude]="account.children[0].settings.longitude"
                    >
                        <map-marker
                            [latitude]="account.children[0].settings.latitude"
                            [longitude]="account.children[0].settings.longitude"
                        ></map-marker>
                    </map-view>
                </div>
            </div>
        </div>
    </div>

    <div class="section-header-container">
        <h4 class="section-header" data-automation="current-status-label">
            {{ 'sites.overview' | translate }}
        </h4>
    </div>
    <div class="details-container">
        <div class="details-row">
            <div class="account-sites-container">
                <deros-dashboard-site-table
                    [sites]="account.children"
                ></deros-dashboard-site-table>
            </div>
        </div>
    </div>

    <div class="section-header-container">
        <h4 class="section-header" data-automation="current-status-label">
            {{ 'aggregated.demand.profile' | translate }}
        </h4>
    </div>
    <div class="details-container">
        <div class="details-row">
            <div class="details-half-row location-container">
                <div id="account-aggregated-demand-profile">
                    <deros-dashboard-demand-profile-chart></deros-dashboard-demand-profile-chart>
                </div>
            </div>
        </div>
    </div>
</div>
