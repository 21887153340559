import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';
import localePl from '@angular/common/locales/pl';
import localeZh from '@angular/common/locales/zh';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
} from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG } from '@ng-maps/google';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPopperjsModule, NgxPopperjsPlacements } from 'ngx-popperjs';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AuthHttpInterceptor,
  ErrorInterceptor,
  LoaderInterceptor,
  MockHttpInterceptor,
} from './interceptor';
import { CoreRoutingModule } from './routing';
import { Locale, PermissionType, Timezone } from '@model';
import {
  AccountService,
  CookieService,
  DashboardService,
  DeveloperService,
  DialogService,
  ExcelService,
  GlobalAlertService,
  GoogleService,
  I18nService,
  IconsService,
  LatestUpdateService,
  LoaderService,
  LocalesService,
  LoginRedirectService,
  PermissionService,
  ResourceService,
  RouterService,
  SidenavService,
  SiteService,
  TimezonesService,
  TreeService,
} from '@service';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

const initRouterCheck = (routerService: RouterService) => (): Promise<void> =>
  routerService.initRouterCheck();

const initGoogleApiKey = (google: GoogleService) => (): Observable<string> =>
  google.load();

const initIcon = (icon: IconsService) => (): Promise<void> => icon.load();

const initTimezones =
  (timezone: TimezonesService) => (): Observable<Timezone[]> =>
    timezone.loadTimezones();

const initLocales = (locale: LocalesService) => (): Observable<Locale[]> =>
  locale.setLocales();

const initLoginRedirect =
  (loginRedirect: LoginRedirectService) => (): Promise<void> =>
    loginRedirect.loadLoginPage();

const initPermissions =
  (permissionsService: PermissionService) => (): Observable<PermissionType> =>
    permissionsService.getPermissions().pipe(take(1));

const placement = NgxPopperjsPlacements.RIGHT;

@NgModule({
  imports: [
    CoreRoutingModule,
    NgMapsCoreModule,
    NgxPopperjsModule.forRoot({ placement }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [CoreRoutingModule, NgMapsCoreModule, TranslateModule],
  providers: [
    AccountService,
    CookieService,
    DashboardService,
    DeveloperService,
    DialogService,
    ExcelService,
    GlobalAlertService,
    GoogleService,
    I18nService,
    IconsService,
    TreeService,
    LatestUpdateService,
    LoaderService,
    LocalesService,
    LoginRedirectService,
    ResourceService,
    RouterService,
    SidenavService,
    SiteService,
    AccountService,
    TimezonesService,
    PermissionService,
    {
      provide: APP_INITIALIZER,
      useFactory: initIcon,
      deps: [IconsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLocales,
      deps: [LocalesService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLoginRedirect,
      deps: [LoginRedirectService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initGoogleApiKey,
      deps: [GoogleService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initPermissions,
      deps: [PermissionService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initRouterCheck,
      deps: [RouterService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initTimezones,
      deps: [TimezonesService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useClass: GoogleService,
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CoreModule {
  // Make sure CoreModule is imported only by one NgModule the AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
