<div class="app-global-alert">
    <div *ngIf="isErrorAlertVisible" class="global-message-wrapper">
        <div [ngClass]="messageClassName">
            <span [ngClass]="iconClassName"></span>
            <span id="global-alert" data-automation="global-message">
                {{ message.message }}
            </span>
        </div>
    </div>
</div>
