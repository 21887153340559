import { DOCUMENT } from '@angular/common';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
// eslint-disable-next-line import/named
import { firstValueFrom } from 'rxjs';

@Injectable()
export class LoginRedirectService {
  private logout = false;

  private readonly _LOGIN_ENDPOINT = 'links/login';
  private _urlToNavigate: string;
  private httpClient: HttpClient;

  constructor(
    handler: HttpBackend,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  get urlToNavigate(): string {
    return this._urlToNavigate;
  }

  loadLoginPage(): Promise<void> {
    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'No-Auth': 'True',
    });
    const loginPromise = this.httpClient.get(
      `/api/v1/${this._LOGIN_ENDPOINT}`,
      { headers: reqHeader },
    );
    return firstValueFrom(loginPromise).then(
      data => {
        const loginPage = data['data'];
        this._urlToNavigate = `${loginPage}?redirect_url=${location.href}`;
        if (this.logout) {
          this.document.location.href = this._urlToNavigate;
        }
        return Promise.resolve();
      },
      err => {
        console.error('redirect threw error', err);
        return Promise.reject(err);
      },
    );
  }

  setLogout(): void {
    this.logout = true;
  }
}
