export interface LatestUpdate {
  timestamp: number;
  version: string;
  link: string;
  update: string;
}

export class LatestUpdate {
  constructor() {
    (this.timestamp = 1689289356),
      (this.version = '0.0.0'),
      (this.link = 'https://dev-apps.enelx.net/'),
      (this.update = 'DER.OS Update');
  }
}
