import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { mergeMap, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { Account } from '@model';
import { AccountService, SiteService } from '@service';

@Injectable()
// eslint-disable-next-line prettier/prettier
export class AccountResolver {
  constructor(
    private accountService: AccountService,
    private siteService: SiteService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Account> {
    const accountId =
      route.queryParamMap.get('accountId') || route.paramMap.get('accountId');

    return this.accountService.getAccount$(accountId).pipe(
      mergeMap(account => {
        return this.siteService.getSites$(account.id).pipe(
          take(1),
          map(sites => ({ ...account, children: sites })),
        );
      }),
    );
  }
}
