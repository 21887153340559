import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ResourceService } from './resource.service';
import { LatestUpdate } from '@model';

@Injectable()
export class LatestUpdateService {
  updates$: Subject<LatestUpdate[]>;

  constructor(private resourceService: ResourceService<LatestUpdate>) {
    this.updates$ = new Subject<LatestUpdate[]>();
  }

  setUpdates(): Observable<LatestUpdate[]> {
    return this.resourceService
      .getList('latest-updates')
      .pipe(tap(updates => this.updates$.next(updates)));
  }
}
