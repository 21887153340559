<deros-dashboard-global-alert></deros-dashboard-global-alert>

<global-nav
  [appLabel]="'DEROS Dashboard'"
  [userData$]="user$"
  [applications$]="applications$"
></global-nav>

<mat-sidenav-container (backdropClick)="sidenavOpen = false">
    <mat-sidenav
        #sidenav
        class="sidenav-container"
        fixedInViewport="true"
        fixedTopGap="60"
        [mode]="isLargeScreen() ? 'side' : 'over'"
        [opened]="sidenavOpen"
    >
        <div class="sidenav-header">
            <div class="flex justify-between items-center">
                <h1 class="page-title" data-automation="listTitle">
                    {{ 'APP.TITLE' | translate }}
                </h1>
                <button
                    mat-icon-button
                    color="accent"
                    aria-label="Menu"
                    class="toggle-sidenav flex justify-center items-center"
                    data-automation="toggle-sidenav"
                    (click)="sidenavOpen = !sidenavOpen"
                >
                    <mat-icon>double_arrow</mat-icon>
                </button>
            </div>
        </div>
        <div
            class="sidenav-component-container"
            data-automation="tree-container"
        >
            <deros-dashboard-tree></deros-dashboard-tree>
        </div>
    </mat-sidenav>

    <mat-sidenav-content
        data-automation="sidenav-content"
        [class.sidenav-content-stretch]="!sidenavOpen"
    >
        <div data-automation="scrollable-container" class="full overflow-auto">
            <div data-automation="main-container" class="main-container">
                <deros-dashboard-loader></deros-dashboard-loader>
                <router-outlet></router-outlet>
                <!--          <ngx-global-contact-footer></ngx-global-contact-footer>-->
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
