<input
    type="file"
    #file
    style="display: none"
    (change)="onFilesAdded()"
    [multiple]="multiple"
    [accept]="accept"
/>

<div
    #uploader
    *ngIf="!files?.size && !showFiles && !showLoader"
    class="flex flex-col justify-evenly items-stretch"
>
    <div
        class="uploader flex justify-center items-center"
        (click)="addFiles()"
        (dragover)="dragOver($event)"
        (dragenter)="dragEnter($event)"
        (dragleave)="dragLeave($event)"
        (drop)="filesDrop($event)"
    >
        <mat-icon svgIcon="upload-new" class="icon-upload"></mat-icon>
        <div class="flex flex-col justify-center items-start">
            <h1>{{ title }}</h1>
            <span>{{ 'uploader.subtitle' | translate }}</span>
        </div>
    </div>
</div>

<mat-list *ngIf="!showError && uploading && !showLoader" class="full">
    <mat-list-item *ngFor="let file of files">
        <span class="label-full">{{ file.name }}</span>
        <!--        <mat-progress-bar-->
        <!--                *ngIf="progress"-->
        <!--                mode="determinate"-->
        <!--                [value]="progress[file.name].progress | async"-->
        <!--        ></mat-progress-bar>-->
    </mat-list-item>
</mat-list>

<div *ngIf="showFiles && !showLoader" class="full">
    <ng-container *ngIf="multiple">
        <div *ngFor="let file of $any(object)">
            <div class="flex justify-end items-center file-container">
                <span class="file-label">{{ file[labelField] }}</span>
                <mat-icon
                    svgIcon="clear-pink"
                    class="pointer"
                    (click)="remove(file[idField])"
                ></mat-icon>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!multiple">
        <div class="flex justify-end items-center file-container">
            <span class="file-label">
                {{ fileLabel }}: {{ object[labelField] }}
            </span>
            <mat-icon
                svgIcon="clear-pink"
                class="pointer"
                (click)="remove(file[idField])"
            ></mat-icon>
        </div>
    </ng-container>
</div>

<div *ngIf="showError" class="full flex justify-start items-center">
    <span class="text-error">There was an error with upload. Try again</span>
    <button mat-ripple class="button" (click)="clear()">
        <mat-icon svgIcon="reset" class="icon-reset"></mat-icon>
    </button>
</div>

<!--<div *ngIf="showLoader" class="full flex justify-start items-center">-->
<!--    <mat-spinner></mat-spinner>-->
<!--</div>-->
