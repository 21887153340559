<div
    class="section-header-container"
    data-automation="demand-profile-header-container"
>
    <h4 class="section-header" data-automation="demand-profile-header">
        {{ 'demand.profile' | translate }}
    </h4>
</div>

<div id="chart-container"></div>
