<div class="flex flex-col justify-start items-center">
    <div class="w-full flex justify-center items-center title-heading">
        <div
            class="flex justify-start items-center justify-self-start margin-right-auto icon-header"
        >
            <div class="icon-container">
                <mat-icon svgIcon="manage-white" class="header-icon"></mat-icon>
            </div>
            <div>
                <h1 data-automation="site-header">{{ site.displayLabel }}</h1>
            </div>

            <div class="site-tabs">
                <div class="flex items-center">
                    <h3
                        [ngClass]="{ 'active-heading': mode === 'home' }"
                        id="site-home-tab"
                        (click)="changeTab('home')"
                        class="tab"
                    >
                        {{ 'tabs.home' | translate }}
                    </h3>
                    <h3
                        [ngClass]="{ 'active-heading': mode === 'diagnostics' }"
                        id="site-diagnostics-tab"
                        (click)="changeTab('diagnostics')"
                        class="tab"
                    >
                        {{ 'tabs.diagnostics' | translate }}
                    </h3>
                    <h3
                        [ngClass]="{ 'active-heading': mode === 'financial' }"
                        id="site-financial-tab"
                        (click)="changeTab('financial')"
                        class="tab"
                    >
                        {{ 'tabs.financial' | translate }}
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngSwitch]="mode" class="page-container">
    <deros-dashboard-site-home
        *ngSwitchCase="'home'"
        [site]="site"
    ></deros-dashboard-site-home>
    <deros-dashboard-site-diagnostics
        *ngSwitchCase="'diagnostics'"
        [site]="site"
    ></deros-dashboard-site-diagnostics>
</div>
