import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import {
  ApplicationService,
  DeveloperService,
  I18nService,
  LoaderService,
  ResourceService,
  RouterService,
  SidenavService,
  TreeService,
  UserService,
} from '@service';
import { environment } from 'src/environments/environment.prod';
import { Application, User } from 'global-nav';

@Component({
  selector: 'deros-dashboard-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('searchInput') input: ElementRef;
  sidenavOpen = true;
  disabledInput = true;
  navUrl: string;
  subscriptions: Subscription[] = [];
  user$: Observable<User>;
  applications$: Observable<Application[]>;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private sidenavService: SidenavService,
    private developerService: DeveloperService,
    private treeService: TreeService,
    public loaderService: LoaderService,
    public routerService: RouterService,
    private resourceService: ResourceService<string>,
    private userService: UserService,
    private applicationService: ApplicationService,
  ) {
    // this.loaderService.setExcludedUiUrls('tab=results');
    this.user$ = userService.getUser();
    this.applications$ = applicationService.getApplications();
    this.loaderService.regexEndpoints = ['ess-parameters', 'permission', 'run'];
    this.getNavUrl();
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = 'en';
    const brand = 'DESCO';
    this.translateService.setDefaultLang(
      `${defaults.locale}.${defaults.brand.toUpperCase()}`,
    );
    let translateSubscription = this.translateService
      .use(`${locale}.${brand}`)
      .subscribe(() => {
        this.translateService.get('APP.TITLE').subscribe(result => {
          document.title = result;
        });
      });

    const closeSubscription = this.sidenavService.close$.subscribe(
      () => (this.sidenavOpen = false),
    );

    this.treeService.enableFilterInput$.subscribe(
      enable => (this.disabledInput = !enable),
    );

    this.subscriptions.push(translateSubscription, closeSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    const logo = document.querySelector('.logo') as HTMLElement;
    logo.style.cursor = 'pointer';
    logo.addEventListener('click', () => {
      this.router.navigateByUrl('/');
      this.treeService.deselectAll();
    });

    this.sidenavService.setSidenav(this.sidenav);

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        // untilDestroyed(this),
        // filter(Boolean),
        debounceTime(100),
        distinctUntilChanged(),
        tap(async () => {
          this.developerService.sendValue(this.input.nativeElement.value);
        }),
      )
      .subscribe();
  }

  private getNavUrl(): void {
    this.resourceService
      .get('links/nav')
      .subscribe(navUrl => (this.navUrl = navUrl));
  }

  isLargeScreen(): boolean {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return width > 1280;
  }
}
