<ng-container *ngIf="loaderService.isLoading">
    <div class="loader-overlay" data-automation="loader-overlay"></div>
    <div
        class="flex flex-col justify-start items-center loader-container"
        data-automation="loader"
    >
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'loading' | translate }}...</h2>
    </div>
</ng-container>
